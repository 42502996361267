//jsxhook

import {
  Address,
  Cart,
  LineItem,
  Money,
  Order
} from "@commercetools/platform-sdk"
import { sendGTMEvent } from "@next/third-parties/google"
import { Currency } from "@sixty-six-north/i18n"
import { Option } from "funfix-core"
import _first from "lodash/first"
import _head from "lodash/head"
import _last from "lodash/last"
import { CartCalculator } from "../cart/CartCalculator"
import { useCartService } from "../cart/CartServiceHook"
import { ImageView } from "../category/ProductListing"
import { EU, IS, UK } from "../i18n/Region"
import { useCommerceToolsStore } from "../i18n/StoreProvider"
import { findPriceForCurrencyCode } from "../product/DetailVariantProxy"
import { CoreProductInformation } from "../product/models/DetailedProductInformation"
import { DomainCategory } from "../product/models/DomainCategory"
import { productDal } from "../product/ProductDal"
import { SKU, skuComponents } from "../product/VariantProxyI"
import { createLogger } from "../utils/createLogger"
import { useExchangeRateContext } from "./ExchangeRatesContext"
import { NewsletterSignUpTrackingData } from "./NewsletterSignUpTrackingData"

const logger = createLogger("AnalyticsHooks")

export interface CheckoutActionField {
  step: number
  option: string | undefined
}

interface AnalyticsProductDetails {
  id: string | undefined
  name: string
  sku: string
  variant: string
  category: string
  parentCategory?: string
  price: string
  currency: string
}

export interface RawAnalyticsProductDetails {
  sku: SKU
  name: string
  category: DomainCategory
  price: Money
}

type AnalyticsEvent =
  | "impressionClick"
  | "impressionView"
  | "productPage"
  | "addToCart"
  | "view_cart"
  | "applepay_checkout"
  | "add_shipping_info"
  | "add_shipping_method"
  | "add_shipping_address"
  | "add_billing_address"
  | "add_payment_info"
  | "review"
  | "checkout"
  | "purchase"
  | "viewOptionChoice"
  | "spellingAudioPlayed"
  | "spellingTooltipDisplayed"
  | "checkInventoryClick"
  | "colorClick"
  | "imageZoom"
  | "imageScroll"
  | "formSubmission"

const randomId: () => string = () =>
  `${Math.floor(Math.random() * 10000000000)}`

function recordGTMEvent(
  event: AnalyticsEvent,
  data: Record<string, unknown>,
  eventId: string = randomId()
) {
  sendGTMEvent({
    event,
    eventId,
    ...data
  })
}

export const useGoogleAnalytics = () => {
  const exchangeRatesContext = useExchangeRateContext()
  const cartService = useCartService()
  const commerceToolsStore = useCommerceToolsStore()

  function productsFor(lineItems: LineItem[]) {
    const products = lineItems.map(async (item: LineItem) => {
      return productDal
        .projectionById(item.productId, cartService.store, commerceToolsStore)
        .then(({ key, name, categories }: CoreProductInformation) => {
          const productCategory = _head(
            categories.filter(it => it !== undefined)
          )

          const parentCategory = _last(
            productCategory?.ancestors.map(cat => cat.name[UK.language])
          )
          const sku = (item.variant.sku || "product-color-size") as SKU
          const { color } = skuComponents(sku)
          return {
            id: key,
            name,
            price: exchangeRatesContext.convertToEuroString(
              CartCalculator.lineItemTotalPrice(item)
            ),
            quantity: item.quantity,
            category: productCategory ? productCategory.name[UK.language] : "",
            variant: color,
            parentCategory: parentCategory ? parentCategory : "",
            dimension19: sku
          }
        })
    })

    return Promise.all(products)
  }

  const reportStep = (cart: Cart, actionField: CheckoutActionField) => {
    switch (actionField.step) {
      case 0:
        reportCkoStepToGA4(cart, "applepay_checkout")
        break
      case 1:
        reportCkoStepToGA4(cart, "view_cart")
        break
      case 2:
        reportCkoStepToGA4(cart, "add_shipping_info")
        break
      case 3:
        reportCkoStepToGA4(cart, "add_shipping_method", actionField.option)
        break
      case 4:
        reportCkoStepToGA4(cart, "add_payment_info", actionField.option)
        break
      case 5:
        reportCkoStepToGA4(cart, "review")
        break
      default:
        logger.error("Can't report checkout action => ", actionField)
    }
    reportCheckoutStepToGUA(cart, actionField)
  }

  const reportCkoStepToGA4 = (
    cart: Cart,
    event: AnalyticsEvent,
    option = ""
  ) => {
    recordGTMEvent(event, {
      value: exchangeRatesContext.convertToEuroString(cart.totalPrice),
      currency: "EUR",
      option,
      items: cart.lineItems.map(lineItem => {
        return {
          item_id: lineItem.productKey,
          item_name: lineItem.name[UK.language] || lineItem.name[IS.language],
          item_variant: lineItem.variant.sku,
          currency: lineItem.totalPrice.currencyCode,
          price: exchangeRatesContext.convertToEuroString(lineItem.totalPrice),
          quantity: lineItem.quantity
        }
      })
    })
  }

  const reportCheckoutStepToGUA = (
    cart: Cart,
    actionField: CheckoutActionField
  ) => {
    productsFor(cart.lineItems)
      .then(products => {
        return {
          ecommerce: {
            currencyCode: "EUR",
            checkout: {
              actionField,
              products
            }
          }
        }
      })
      .then(event => recordGTMEvent("checkout", event))
  }

  const reportAddToBag = (rawData: RawAnalyticsProductDetails) => {
    const {
      id,
      name,
      category,
      parentCategory,
      variant,
      price,
      sku: dimension19,
      currency
    } = convertRawDataObjectToAnalytics(rawData)
    const tagManagerPDPAddToCart = {
      currencyCode: EU.currency,
      ecommerce: {
        add: {
          products: [
            {
              id,
              name,
              category,
              variant,
              parentCategory,
              price,
              dimension19,
              quantity: 1
            }
          ]
        }
      }
    }

    recordGTMEvent("addToCart", tagManagerPDPAddToCart)
  }

  function reportProductDetails(
    productDetails: AnalyticsProductDetails,
    eventName: AnalyticsEvent,
    option = {}
  ) {
    const tagManagerPDPPageView = {
      ...option,
      ecommerce: {
        currencyCode: EU.currency,
        detail: {
          products: [productDetails]
        }
      }
    }
    recordGTMEvent(eventName, tagManagerPDPPageView)
  }

  const reportAddShippingAddress = (
    email?: string,
    shippingAddress?: Address
  ) => {
    if (!shippingAddress) return
    recordGTMEvent("add_shipping_address", {
      email,
      shippingAddress
    })
  }

  const reportAddBillingAddress = (
    email?: string,
    billingAddress?: Address
  ) => {
    if (!billingAddress) return
    recordGTMEvent("add_billing_address", {
      email,
      billingAddress
    })
  }

  const reportPurchase = (order: Order) => {
    function couponCode() {
      const codes = order.discountCodes
        ? order.discountCodes.map(code => code.discountCode?.obj?.code)
        : []
      return Option.of(_first(codes)).getOrElse("")
    }

    productsFor(order.lineItems)
      .then(products => {
        return {
          ecommerce: {
            currencyCode: "EUR",
            purchase: {
              actionField: {
                id: order.orderNumber ? order.orderNumber : "",
                affiliation: "Online Store",
                revenue: exchangeRatesContext.convertToEuroString(
                  CartCalculator.totalPrice(order)
                ),
                tax: exchangeRatesContext.convertToEuroString(
                  CartCalculator.taxes(order)
                ),
                shipping: exchangeRatesContext.convertToEuroString(
                  CartCalculator.shippingCost(order)
                ),
                coupon: couponCode()
              },
              products,
              rakutenLineItems: products.map(product => ({
                quantity: product.quantity,
                unitPrice: product.price,
                unitPriceLessTax: product.price,
                SKU: product.id,
                productName: product.name
              }))
            }
          }
        }
      })
      .then(event => recordGTMEvent("purchase", event))
  }

  function convertRawDataObjectToAnalytics(
    rawData: RawAnalyticsProductDetails
  ) {
    return convertRawDataToAnalytics(
      rawData.sku,
      rawData.price,
      rawData.name,
      rawData.category
    )
  }

  function convertRawDataToAnalytics(
    sku: SKU,
    price: Money,
    name: string,
    category: DomainCategory
  ) {
    const { product: id, color: variant } = skuComponents(sku)
    const euPrice = exchangeRatesContext.convertToEuroString(price)

    return {
      id,
      variant,
      name,
      sku,
      category: category ? category.name[UK.language] : "",
      parentCategory: _last(
        category?.ancestors.map(cat => cat.name[UK.language])
      ),
      currency: EU.currency,
      price: euPrice
    }
  }

  const reportImpressionClick = ({
    sku,
    name,
    category,
    price
  }: RawAnalyticsProductDetails) => {
    reportProductDetails(
      convertRawDataToAnalytics(sku, price, name, category),
      "impressionClick"
    )
  }

  const reportProductPageView = ({
    sku,
    name,
    category,
    price
  }: RawAnalyticsProductDetails) => {
    reportProductDetails(
      convertRawDataToAnalytics(sku, price, name, category),
      "productPage"
    )
  }

  const reportImageScroll = (
    currentRawAnalyticsData: RawAnalyticsProductDetails
  ) => reportArbitraryEvent(currentRawAnalyticsData, "imageScroll")

  const reportImageZoomClick = (
    currentRawAnalyticsData: RawAnalyticsProductDetails
  ) => reportArbitraryEvent(currentRawAnalyticsData, "imageZoom")

  const reportViewOptionClick = (chosenOption: ImageView) => {
    const viewOptionChoice = {
      chosenOption
    }
    recordGTMEvent("viewOptionChoice", viewOptionChoice)
  }

  const reportColorClick = (
    currentRawAnalyticsData: RawAnalyticsProductDetails
  ) => reportArbitraryEvent(currentRawAnalyticsData, "colorClick")

  const reportStoreInventoryClick = (
    currentRawAnalyticsData: RawAnalyticsProductDetails
  ) => reportArbitraryEvent(currentRawAnalyticsData, "checkInventoryClick")

  const reportArbitraryEvent = (
    { sku, name, category, price }: RawAnalyticsProductDetails,
    event: AnalyticsEvent
  ) => {
    recordGTMEvent(event, {
      product: convertRawDataToAnalytics(sku, price, name, category)
    })
  }

  const reportNewsLetterSignUp = (utm: NewsletterSignUpTrackingData) => {
    recordGTMEvent("formSubmission", {
      formType: "newsletter",
      formCampaign: utm.campaign,
      formSource: utm.source,
      formMedium: utm.medium,
      formContent: utm.content
    })
  }

  const reportListingPage = (
    products: CoreProductInformation[],
    category: DomainCategory
  ) => {
    const impressions = products.map((it, index) => {
      const mainColorway = Object.values(it.colorways).find(c => c.index === 0)
      const price = findPriceForCurrencyCode(Currency.ISK, mainColorway?.prices)
        .orElse(findPriceForCurrencyCode(Currency.EUR))
        .map(p => p.discounted?.value || p.value)
        .getOrElse({ centAmount: 0, currencyCode: EU.currency })

      const priceInEuro: Money = exchangeRatesContext.convertToEuros(price)
      return {
        id: it.key,
        name: it.name,
        variant: mainColorway?.color,
        price: priceInEuro.centAmount / 100,
        category: category ? category.name[UK.language] : "",
        parentCategory:
          _last(category?.ancestors.map(cat => cat.name[UK.language])) || "",
        dimension19: it.masterSku,
        list: "category",
        position: index + 1
      }
    })

    recordGTMEvent("impressionView", {
      ecommerce: {
        currencyCode: EU.currency,
        impressions
      }
    })
  }

  const clear = () => {
    sendGTMEvent({
      ecommerce: {}
    })
  }

  return {
    reportStep,
    reportAddToBag,
    reportProductPageView,
    reportPurchase,
    reportColorClick,
    reportImpressionClick,
    reportListingPage,
    reportNewsLetterSignUp,
    reportStoreInventoryClick,
    reportImageScroll,
    reportImageZoomClick,
    reportViewOptionClick,
    reportAddShippingAddress,
    reportAddBillingAddress,
    reportArbitraryEvent,
    clear
  }
}
